<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card :elevation="isEmbedded ? 0 : 2">
      <v-card-title v-if="!isEmbeddedInApp" class="display-2">Request Profile Removal</v-card-title>

      <div v-if="showDeletedPrompt">
        <v-card-text>   
          <v-alert type="success">
            <p>Your profile will be removed soon. </p>
            
            <p>You can now log out from our app and/or website to complete this action.</p>
          </v-alert>

          <p>
            You may contact us within 7 days in case you rather keep your data. Please make sure to include the profile ID displayed below in such a request.
          </p>

          <v-text-field
            :value="profile.id"
            label="Profile ID"
            readonly
            disabled
            color="grey"
            prepend-icon="fa fa-id-badge"
            >
            <template v-slot:append>
              <v-btn
                text
                color="primary"
                class="ma-0"
                @click="copyProfileId">
                Copy
              </v-btn>
            </template>
          </v-text-field>

          <p>
            <v-btn outlined :to="{name: 'profileKeep'}">{{ $t('profile.edit.keep-profile') }}</v-btn>
          </p>

        </v-card-text>
      </div>
      <div v-else-if="profile">
        <v-card-text>   
          <p>
            On your request, we can delete your profile and all other personal information we store for you. This includes your activities and the devices you have connected.
          </p>
          <p>
            Optionally, we can also delete your results from the events you are participating in, or have previously participated in.
          </p>
          <v-alert type="error">This action cannot be undone once it's completed.</v-alert>

          <h3>Revoking request to remove</h3>
          <p>
            After you've requested your profile to be removed we may take between 30 days and 3 months to permanently remove your personal information, as described in our privacy policy. 
          </p>

          <p>
            After submitting the request you may contact us within 7 days in case you rather keep your data. Please make sure to include the profile ID displayed below in such a request.
          </p>

          <v-text-field
            :value="profile.id"
            label="Profile ID"
            readonly
            disabled
            color="grey"
            prepend-icon="fa fa-id-badge"
            >
            <template v-slot:append>
              <v-btn
                text
                color="primary"
                class="ma-0"
                @click="copyProfileId">
                Copy
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            :value="profile.email"
            label="Email"
            readonly
            disabled
            color="grey"
            prepend-icon="fa fa-envelope"
            /> 
        </v-card-text>

        
        <v-card-text>   
          <v-checkbox v-model="confirmed" label="I confirm I want to delete my profile and all my activities and personal information. This action cannot be undone once it's completed." hint="" persistent-hint />

          <v-checkbox v-model="deleteResults" label="Also delete all my results in the events I have participated." hint="" persistent-hint />

          <v-btn class="mt-8" block :disabled="!isValid" color="primary" :loading="$store.getters.isLoading" @click="submit">Request Removal</v-btn>
        </v-card-text>   

      </div>      

    </v-card>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import Header from './_Header.vue';

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: {name: 'profile'} },
        { text: 'Request Removal', disabled: true },
      ],
      profile: null,
      deleteResults: false,
      confirmed: false,
      showDeletedPrompt: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    
    copyProfileId() {
      this.$helpers.copyToClipboard(this.profile.id, this.$toast);
    },

    async submit() {
      await profileService.delete(this.deleteResults);
      this.showDeletedPrompt = true;
    },

    async getProfile() {
      if (this.user) {
        var response = await profileService.get();
        this.profile = response.data;
        this.showDeletedPrompt = this.profile.delete_initiated;
      }
    },
  },
  computed: {
    isValid() {
      return this.confirmed;
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

